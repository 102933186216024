
:local .LoginForm {
  position: relative;
  margin: 10px;
}

:local .LoginForm header {
  font: 1.2rem bold;
}

:local .LoginForm footer {
  text-align:  right;
}

:local .TextField {
  background: #fff;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0;
}

:local .LoginFormInput {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
}

:local .Row {
  padding: 2px 0;
}

:local .Submit {
  background: #1779ba;
  width: 100px;
  min-height: 30px;
  color: #FFF;
}

