*{
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}

html {
  font-size: 87.5%;
  font-family: "Roboto","\30D2\30E9\30AE\30CE\89D2\30B4   Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "\30E1\30A4\30EA\30AA", Meiryo, "\FF2D\FF33   \FF30\30B4\30B7\30C3\30AF", "MS PGothic", sans-serif;
  line-height: 1.6;
  letter-spacing: .05em;
}
body {
  font-size: 14px;
  font-size: 1rem;
  background: #000;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


._2Qq4Bvs0uaWCZF3pna48mF {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._2Pv7Jl1le9p9b4gi5yTJzC {
  border-radius: 4px;
  color: #FFF;
  width: 80%;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.6);
}

._2ewsQNWL2vpG0L-zUb2tYB {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
}

.MWmcgg8OY_VrY18O1yeZ4 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

._2iJXB0YzHZsjQBJjIA0ss2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


._3Rhua-cGE0YpC0jdAjFpL2 {
  position: relative;
  margin: 10px;
}

._3Rhua-cGE0YpC0jdAjFpL2 header {
  font: 1.2rem bold;
}

._3Rhua-cGE0YpC0jdAjFpL2 footer {
  text-align:  right;
}

.eGSj9aehSeHshdJUUyA32 {
  background: #fff;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0;
}

.ojNDo9-b2jR505hJwO3k4 {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
}

._3LGZI5f3XBFHswUaPCdwK9 {
  padding: 2px 0;
}

._9Pcq1YvXdY_-GvKtav6Tc {
  background: #1779ba;
  width: 100px;
  min-height: 30px;
  color: #FFF;
}


.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

